import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppStep1 from '../assets/img/app_step1.png';
import AppStep2 from '../assets/img/app_step2.png';
import AppStep3 from '../assets/img/app_step3.png';
import AppStep4 from '../assets/img/app_step4.png';
import AppStep5 from '../assets/img/app_step5.png';
import AppStep6 from '../assets/img/app_step6.png';
import AppStep7 from '../assets/img/app_step7.png';

const TheGrotonApp = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    arrows: true,
                },
            },
        ],
    };

    return (
        <>
            <section className="gotronapp">
                <div className="container">
                    <div className="sec_top_co">
                        <h2>The GOTRON App!</h2>
                        <h3>How It Works</h3>
                        <p>To ride, download the App using the QR code or searching for ``GOTRON`` in the App store or Google Play
                        </p>
                    </div>
                    <div className="app-guide-slider">
                        <Slider {...settings}>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 1</h3>
                                            <ul>
                                                <li>Create an Account</li>
                                                <li>Tap "continue with mobile number" and enter your mobile number.</li>
                                                <li>Enter the 4-digit code sent to your number.</li>
                                                <li>Create password.</li>
                                                <li>Enter your full name and book your ride.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={AppStep1} alt="step 1"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 2: Select ``Book Taxi``</h3>
                                            <ul>
                                                <li>On the GOTRON App homepage, tap on " Book Taxi."</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={AppStep2} alt="step 2"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 3: Enter drop-off and pick-up locations.</h3>
                                            <ul>
                                                <li>Type pick-up point on "From" box.</li>
                                                <li>Go to the "Where to?" box and enter your destination.</li>
                                                <li>Map shows where you are. If correct hit the "Confirm Pick-up" button.</li>
                                                <li>Save your most frequented locations (Work, home, etc.) for faster booking!</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6"><img src={AppStep3} alt="step 3"></img></div>

                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 4: Choose your service option and ride.</h3>
                                            <ul>
                                                <li>Swipe and scroll up and down to find the service option and the ride that fits your needs and style.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6"><img src={AppStep4} alt="step 3"></img></div>
                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 5: Set up payment method.</h3>
                                            <ul>
                                                <li>Choose cards, Apple pay etc.</li>
                                                <li>No Cash. Check fare estimate then tap the "Go" button.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6"><img src={AppStep5} alt="step 3"></img></div>
                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 6: Track your ride in real time</h3>
                                            <ul>
                                                <li>Driver's profile will be displayed on the app with all details (Name, photo and car's license plate number).</li>
                                                <li>Get updates about their location as they make their way to you.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6"><img src={AppStep6} alt="step 3"></img></div>
                                </div>
                            </div>
                            <div className="app_steps_box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="step-cont">
                                            <h3>Step 7: Spot-Hop-Enjoy the Ride</h3>
                                            <ul>
                                                <li>Once you spot your ride, hop in, sit back, kick off your shoes and enjoy the ride.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6"><img src={AppStep7} alt="step 3"></img></div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

        </>
    );
};

export default TheGrotonApp;
