import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/img/logo.png';
import { Link } from "react-router-dom";
const Header = () => {
    return (
        <header>
            <Navbar expand="lg">
                <Container className="align-items-end">
                    <Navbar.Brand href="#home">
                        <img src={Logo} className="brand_logo" alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto p-0">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <NavDropdown title="Services" id="service-dropdown">
                                <NavDropdown.Item as={Link} to="the-gotron-experience">The GotronXperience</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Taxi Services</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Rates</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Download</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Gateway" id="gateway-dropdown">
                                <NavDropdown.Item href="#action/3.1">About Us</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Service Areas</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Membership</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">FAQs</NavDropdown.Item>

                            </NavDropdown>
                            <Nav.Link href="#ForBusiness">For Business</Nav.Link>
                            <Nav.Link href="#ForDrivers">For Drivers</Nav.Link>
                        </Nav>

                        <Nav className="ms-auto">
                            <div className="righ-side-btns">
                                <a href="">Login</a>
                                <a href="">Signup</a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
