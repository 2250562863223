import React from "react";
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
    return (
        <>
            <section className="home_faqs">
                <div className="container">
                    <div className="faqs_top">
                        <h6>FAQs</h6>
                        <h2>We've got Answers for your Questions</h2>
                        <p>For more questions about our taxi service, please contact us in any convenient way, and also read the information below.
                        </p>
                    </div>
                    <div className="faqs_con pb-3">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How to book a ride?</Accordion.Header>
                                <Accordion.Body>
                                    You can book a ride by filling out the form on our website. In this case, we will contact you to confirm your request. Or you can download our app.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Where can I download your app?</Accordion.Header>
                                <Accordion.Body>
                                    The GOTRON app is available for iOS and Android smartphones. Download it from Google Play and App Store.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Do you provide transfer services from the airport?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, we can pick you up at DFW Airport, Love Field International Airport, and any International Airport in Texas.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <center>
                        <a href="" className="theme-btn">Read More</a>
                    </center>
                </div>
            </section>
        </>
    );
};
export default Faqs;
