import React from "react";
import GoGuarded from '../assets/img/g1.png';
import GoGuided from '../assets/img/g2.png';
import GoGreen from '../assets/img/g3.png';
import GoGotron from '../assets/img/g4.png';
const GoWithGotron = () => {
    return (
        <>
            <section className="goWithG">
                <div className="container">
                    <h2>Why Go With GOTRON?</h2>
                    <div className="gotron_guide">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="guide-box">
                                    <img src={GoGuarded} alt="guide 1"></img>
                                    <h4>Go Guarded</h4>
                                    <p>Safety is our top priority, with an enhanced safety program aimed at saving lives and improving road safety.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="guide-box">
                                    <img src={GoGuided} alt="guide 1"></img>
                                    <h4>Go Guided</h4>
                                    <p>We offer a comprehensive travel solution for an exceptional travel experience.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="guide-box">
                                    <img src={GoGreen} alt="guide 1"></img>
                                    <h4>Go Green</h4>
                                    <p>Our services utilize carbon-neutral and electric transportation, representing the future of luxury travel.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="guide-box">
                                    <img src={GoGotron} alt="guide 1"></img>
                                    <h4>Go Gotron</h4>
                                    <p>Our professional drivers are committed to maintaining high standards, offering competitive, transparent, and fair pricing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" className="theme-btn mt-2">Download our app and Go With GOTRON</a>
                </div>
            </section>
        </>
    );
}
export default GoWithGotron;