import React from "react";
import { Row, Col } from "react-bootstrap";
import QR from '../assets/img/qr.png'
import GooglePlayImg from '../assets/img/g_play.png'
import AppStore from '../assets/img/a_store.png';
import { IoIosCall } from "react-icons/io";

const Banner = () => {
    return (
        <>
            <section className="hero-banner">
                <div className="container">
                    <Row>
                        <Col xs={12} sm={10} md={8} lg={6}>
                            <div className="banner-cont">
                                <h1>Taxi. Transformed.</h1>
                                <p>GOTRON is an eco-friendly urban transportation solution that uses cutting-edge technology to provide convenient and environmentally friendly mobility options. We aim to offer efficient and affordable taxi service while prioritizing sustainability.
                                </p>
                                <h6>You need a ride?</h6>
                                <div className="scan-qr">
                                    <p>Scan</p>
                                    <img src={QR} alt="QR Code"></img>
                                </div>
                                <div className="down-app">
                                    <h5>Download the GOTRON app</h5>
                                    <img src={GooglePlayImg} alt="google play store"></img>
                                    <img src={AppStore} alt="App store"></img>
                                </div>
                                <div className="banner-btn">
                                    <a href="" className="theme-btn"><IoIosCall />
                                        Call : 682 208 3276</a>
                                    <a href="" className="theme-btn">Book Online</a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={10} md={8} lg={6}>
                            <div className="banner-video">
                                <h2>Video</h2>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
}

export default Banner;