import React from "react";
const LetsGoThere = () => {
    return (
        <>
            <section className="letsgothere">
                <div className="container">
                    <div className="let_con">
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <h2>Let's Go There!</h2>
                                <p>For a safe and convenient way to shop, commute, attend appointments, or navigate the city without driving, choose GOTRON. Book rides directly from your smartphone and enjoy a pickup in a sleek, eco-friendly vehicle. Experience the next-level service you deserve with the GOTRON App, available for iOS and Android.</p>
                                <button className="theme-btn">Get The App</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default LetsGoThere;