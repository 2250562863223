import React from "react";
import MiniVan from '../assets/img/mini_van.png';
import SUV from '../assets/img/suv.png';
import Sedan from '../assets/img/sedan.png';
import Clock from '../assets/img/clock.png';
import Leaf from '../assets/img/leaf.png';
import Bottle from '../assets/img/bottle.png';
import Umbrella from '../assets/img/umbrella.png';
import Wifi from '../assets/img/wifi.png';
import ChildSeat from '../assets/img/child_seat.png';
import AirFilter from '../assets/img/child_seat.png';
import Telemetric from '../assets/img/telemetric.png';
import User from '../assets/img/user.png';
const MobilityYourWay = () => {
    return (
        <>
            <section className="mobility_way">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="mobil_img">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h6>Mini Van</h6>
                                        <img src={MiniVan} alt="Mini Van"></img>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>SUV</h6>
                                        <img src={SUV} alt="SUV"></img>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Sedan</h6>
                                        <img src={Sedan} alt="Sedan"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="mob_cont">
                                <h2>Mobility Your Way</h2>
                                <p>We provide reliable and accessible modes of transportation, while breaking down barriers to mobility and ensuring that everyone travel safely and comfortably.</p>
                                <p>
                                    Our company-owned fleet of vehicles are designed to meet your needs and expectations
                                </p>
                                <div className="mob_spec">
                                    <ul>
                                        <li>
                                            <img src={Clock} alt="Clock"></img>
                                            <p>24hours, 7days a week</p>
                                        </li>
                                        <li>
                                            <img src={Leaf} alt="Leaf"></img>
                                            <p>100% Hybrid, EVs Brand new Vehicles</p>
                                        </li>
                                        <li>
                                            <img src={Bottle} alt="Bottle"></img>
                                            <p>Complimentary water bottles </p>
                                        </li>
                                        <li>
                                            <img src={Umbrella} alt="Umbrella"></img>
                                            <p>Umbrellas and other souvenirs</p>
                                        </li>
                                        <li>
                                            <img src={Wifi} alt="Wifi"></img>
                                            <p>In-vehicle Wi-Fi and chargers</p>
                                        </li>
                                        <li>
                                            <img src={ChildSeat} alt="ChildSeat"></img>
                                            <p>Child Car seat / Wheelchair accessible vehicles</p>
                                        </li>
                                        <li>
                                            <img src={AirFilter} alt="AirFilter"></img>
                                            <p>Air filter and cleaning between ride</p>
                                        </li>
                                        <li>
                                            <img src={Telemetric} alt="Telemetric"></img>
                                            <p>Telemetric tracking in real-time</p>
                                        </li>
                                        <li>
                                            <img src={User} alt="Telemetric"></img>
                                            <p>Spacious and environmentally conscious options</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default MobilityYourWay;