import React from "react";
const OurGoal = () => {
    return (
        <>
            <section className="our_goal">
                <div className="container">
                    <h2><span>Our Goal:</span> Pushing The Boundaries Of Excellence</h2>
                    <p>Our work will continue to reflect and encompass the innate passion, dedication and hard work we put into every aspect of our Services. We aim to provide clients with a travel experience that blends innovation with traditional taxi service models. Ultimately, we seek to create memorable experiences that showcase the commitment of our team and the loyalty of our clients.
                    </p>
                    <div className="founder">
                        <h6>~ Michael U. Ogbunando</h6>
                        <h6>GOTRON’s Chief Executive Officer & Founder</h6>
                    </div>
                </div>
            </section>
        </>
    );
}
export default OurGoal;