import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Dallas Places Images
import SportingEvent from '../assets/img/sporting_events.jpg';
import Conventions from '../assets/img/conventions.webp';
import Festivals from '../assets/img/festivals.jpg';
import LocalBars from '../assets/img/local_bars.jpg';
import TouristAttraction from '../assets/img/tourist_attractions.jpg';
import Entertainment from '../assets/img/entertainment.jpg';
import ShoppingMall from '../assets/img/shopping_centers.jpg';
const ExploreDallas = () => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    arrows: true,
                },
            },
        ],
    };
    return (
        <>
            <section className="explore-dallas">
                <div className="container">
                    <h2>GOPlaces | Explore Dallas-Fort Worth Metroplex</h2>
                    <p>GOTRON promotes explorations within Dallas-Fort Worth Metroplex, aiming to enhance both travel experiences and ease of navigation to popular destinations.  <b>So where are you heading next?</b></p>
                    <div className="dallas-places">
                        <Slider {...settings}>
                            <div className="dallas-places-box"
                                style={{ backgroundImage: `url(${SportingEvent})` }}>
                                <h3>Sporting Events</h3>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ExploreDallas;