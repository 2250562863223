import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import TheGrotonExperience from './Pages/TheGotronExperience';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='the-gotron-experience' element={<TheGrotonExperience />} />
        </Routes>
        <Footer />
      </div >
    </Router>
  );
}

export default App;

