import React from "react";
import KeenGreen from '../assets/img/keen_green.png'
const KeenOnGreen = () => {
    return (
        <>
            <section className="keen_on_green">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="kenn_cont">
                                <h6>YOU CAN TRUST US SINCE WE ARE:</h6>
                                <h2>KEEN-ON-GREEN</h2>
                                <p>GOTRON’s ``Keen-On-Green`` initiative focuses on making a positive environmental impact through its taxi service. We have integrated environmental considerations into our growth strategies, collaborating with international organizations to support eco-friendly projects aimed at reducing carbon emissions. Hence Keen-On-Green: 2 to offset CO2. <br /><br />
                                    For every two rides taken, GOTRON donates $2 on behalf of clients to ongoing climate change projects in Africa and Asia, which benefit local economies. Clients can track their contributions through our App’s ``Keen-On-Green`` interface, where they’ll see a donation of $2 noted after their second trip. With this program, GOTRON aims to promote sustainability while providing a guilt-free travel experience for its users.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="w-100" src={KeenGreen} alt="KeenOnGreen" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default KeenOnGreen;