import React from "react";
import GoToOptionImg from '../assets/img/go_to_option.png';
const GoToOption = () => {
    return (
        <>
            <section className="go_to_option">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="go_to_cont">
                                <h3>Your Go-To Option</h3>
                                <p>Proudly serving the entire Dallas-Fort Worth metropolitan area... and enhancing the taxi experience to make it safer and more memorable. GOTRON is your reliable taxi service operating in Dallas, Fort Worth, and across North Texas. We serve the entire Dallas-Fort Worth Metroplex, offering our services in cities like Arlington, Irving, and Plano, making it your go-to option for transportation in the area.<br />
                                    Download the GOTRON app to easily book safe, taxi rides allowing you to go when you're ready to go or while you're on the go. Travel on your own terms and as you deserve to.</p>
                                <h3>GOTRON App Features </h3>
                                <p><b>Easy Booking</b> Users can book rides through the GOTRON app available on iOS and Android.
                                    <b>User-Friendly</b> Steps The process involves account creation, selecting pick-up and drop-off locations, choosing service options, setting up payment, and tracking rides in real-time.
                                    <b>Enjoyable Rides </b>Passengers are encouraged to relax and enjoy their journey in comfort.</p>
                            </div>
                            <div className="travel_terms">
                                <h4>Travel on your own terms</h4>
                                <a href="">Book Now</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={GoToOptionImg} className="w-100" alt="go_to_option"></img>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default GoToOption;