import React from "react";
import Banner from "../Components/Banner";
import GoWithGotron from "../Components/GoWIthGotron";
import MobilityYourWay from "../Components/MobiltyYourWay";
import OurGoal from "../Components/OurGoal";
import GoToOption from "../Components/GoToOption";
import MadeForMembers from "../Components/MadeForMembers";
import KeenOnGreen from "../Components/KeenOnGreen";
import Faqs from "../Components/Faqs";
const Home = () => {
    return (
        <>
            <Banner />
            <GoWithGotron />
            <MobilityYourWay />
            <OurGoal />
            <GoToOption />
            <MadeForMembers />
            <KeenOnGreen />
            <Faqs />
        </>
    );
}

export default Home;