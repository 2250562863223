import React from "react";
import Dollar from '../assets/img/dollar.png';
import Car from '../assets/img/car.png';
import Diamond from '../assets/img/diamond.png';
import Calender from '../assets/img/calender.png';
import Location from '../assets/img/location.png';
import Map from '../assets/img/map.png';
const MadeForMembers = () => {
    return (
        <>
            <section className="made_for_members">
                <div className="container">
                    <h2>Made For Members</h2>
                    <div className="member_con">
                        <div className="row pb-3">
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Dollar} alt="Dollar"></img>
                                    <h3>Member Discount</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Car} alt="car"></img>
                                    <h3>Pre-Plan Trips</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Diamond} alt="Diamond"></img>
                                    <h3>Ride Rewards</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Calender} alt="Calender"></img>
                                    <h3>15-Day Free Trial</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Location} alt="Location"></img>
                                    <h3>Cancel Anytime</h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mem_cont_box">
                                    <img src={Map} alt="Location"></img>
                                    <h3>Navigate DFW Metroplex and other Cities</h3>
                                </div>
                            </div>
                        </div>
                        <a href="" className="theme-btn" >Explore Membership</a>
                    </div>
                </div>
            </section>
        </>
    );
};
export default MadeForMembers