import React from "react";
import Footerlogo from '../assets/img/logo.png';
import Footerhead1 from '../assets/img/mob.png';
import Footerhead2 from '../assets/img/gaari.png';
import Footerhead3 from '../assets/img/msg.png';
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import LostandFound from '../assets/img/lost_and_found.png';
import AppStore from '../assets/img/a_store.png';
import GooglePlayStore from '../assets/img/g_play.png';
import { FaInstagram } from "react-icons/fa6";
import { FiFacebook } from "react-icons/fi";
import { PiTiktokLogo } from "react-icons/pi";
import { RiLinkedinBoxLine } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    return (
        <>
            <footer>
                <section className="footer">
                    <div className="container">
                        <div className="footer-cont">
                            <div className="row">
                                <div className="col-md-3 foot_log_con">
                                    <img src={Footerlogo}></img>
                                    <h3>© Gotron Technology LLC</h3>
                                </div>
                                <div className="col-md-3">
                                    <div className="foot-head ">
                                        <img src={Footerhead1}></img>
                                        <h3>Go With GOTRON</h3>
                                    </div>
                                    <div className="foot_bottom">
                                        <ul>
                                            <li><a href="">GOTRON Gateway</a></li>
                                            <li><a href="">GOTRON for Drivers</a></li>
                                            <li><a href="">GOTRON for Business</a></li>
                                            <li><a href="">Taxi Services</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="foot-head">
                                        <img src={Footerhead2}></img>
                                        <h3>Guidelines</h3>
                                    </div>
                                    <div className="foot_bottom">
                                        <ul>
                                            <li><a href="">FAQs</a></li>
                                            <li><a href="">Privacy Policy</a></li>
                                            <li><a href="">Terms & Conditions</a></li>
                                            <li><a href="">Zero Tolerance Policy</a></li>
                                            <li><a href="">Non-Discrimination Policy</a></li>
                                            <li><a href="">One Legal Team</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="foot-head">
                                        <img src={Footerhead3}></img>
                                        <h3>Get In Touch</h3>
                                    </div>
                                    <div className="foot_bottom foot_in">
                                        <ul>
                                            <li><MdOutlineMail />
                                                <p>greetings@gogotron.com</p>
                                            </li>
                                            <li><FaPhoneAlt />
                                                <p>682.208.3276</p>
                                            </li>
                                        </ul>
                                        <img src={LostandFound} alt="lost and found"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="social_foot_cont">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-4">
                                <h4>Download the App</h4>
                                <div className="down">
                                    <img src={GooglePlayStore} alt="google play store"></img>
                                    <img src={AppStore} alt="app store"></img>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h3>Connect with Us</h3>
                                <ul className="soc-icons">
                                    <li><a href="https://www.facebook.com/"><FaInstagram /></a></li>
                                    <li><a href="https://www.facebook.com/"><FiFacebook /></a></li>
                                    <li><a href="https://www.facebook.com/"><PiTiktokLogo /></a></li>
                                    <li><a href="https://www.facebook.com/"><RiLinkedinBoxLine /></a></li>
                                    <li><a href="https://www.facebook.com/"><FaXTwitter /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
            <section className="copyright">
               <div className="container">
               <p>© 2024 · All Copyrights Rights Reserved By GOTRON TECHNOLOGY
               </p>
               </div>
            </section>
        </>
    );
}
export default Footer;
