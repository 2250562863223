import React from "react";
import CustomizedCar from '../assets/img/customized_car.png';
import CustomerCare from '../assets/img/customer_care.png';
import Craftmanship from '../assets/img/craftsmanship.png';
const ExperienceExellence = () => {
    return (
        <>
            <section className="experience_exc">
                <div className="container">
                    <h2>Experience Excellence Everytime</h2>
                    <p>Safely connect people with places, things and experiences through technology and innovations that deliver exceptional value to both our <br /> clients and chauffeurs.</p>
                    <div className="excellence_box">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="ex_b">
                                    <img src={CustomizedCar} alt="customized car"></img>
                                    <h3>Customized Cars</h3>
                                    <p>Clean and colorfully branded fleet of GOTRON luxury hybrid and EV vehicles.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="ex_b">
                                    <img src={CustomerCare} alt="CustomerCare"></img>
                                    <h3>Customer Care</h3>
                                    <p>Certainty and convenience enjoyed when you book a ride at prices that are fair to you and our drivers.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="ex_b">
                                    <img src={Craftmanship} alt="CustomerCare"></img>
                                    <h3>Chauffeuring Craftmanship</h3>
                                    <p>Comfortably and confidently travel with professionally trained chauffeurs and thoughtful amenities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ExperienceExellence;