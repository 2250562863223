import React from "react";
const RevolutionizingRides = () => {
    return (
        <section className="revoluting_rides">
            <h1>Revolutionizing Rides, Redefining Journeys </h1>
            <div className="rvol_cont">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="rev_det_box">
                                        <h4>Way to Go!</h4>
                                        <p>GOTRON is a professional, affordable, and eco-friendly luxury taxi service in the Dallas-Fort Worth Metroplex. Aiming to revolutionize urban transportation, GOTRON offers carbon-neutral rides through a fleet of luxury hybrid and electric vehicles, focusing on reducing traffic congestion and environmental impact.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="rev_det_box">
                                        <h4>A Holistic Mobility
                                            Experience</h4>
                                        <p>GOTRON offers a personalized taxi service where every ride can be customized to the client's preferences, including comfort settings, ambient lighting, temperature, and even scent options. This approach aims to make each ride feel uniquely yours.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="rev_det_box">
                                        <h4>Women powering
                                            GOTRON</h4>
                                        <p>GOTRON is a strong proponent for the advancement and empowerment of women in tech. Our taxi service, ``Pink-My-Ride`` and other progressive policies instituted highlight our commitment to ensuring women are equally represented at all levels and across all departments, from finance and operations to marketing, legal and HR</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="rev_det_box">
                                        <h4>Driven to deliver</h4>
                                        <p>GOTRON’s mission is to deliver an affordable and dependable e-hailing service for all, bridging the gap between physical and online transportation. We focus on transforming traditional taxi services through our user-friendly app, outstanding customer service, and a dedicated driver community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default RevolutionizingRides;